/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'fontsource-raleway/latin.css'; // eslint-disable-line
import 'fontsource-work-sans/latin.css'; // eslint-disable-line
import './sub-components/styled-components/layout.scss';

import Header2 from './header2';
import Footer from './footer';
// import { PromoBanner } from './sub-components/promoBanner';
import { ShopSmallBanner } from './sub-components/shopSmallBanner';

let intersectionObserver = null; // eslint-disable-line
if (typeof window !== 'undefined') {
  intersectionObserver = require('intersection-observer'); // eslint-disable-line
}

const StyledMainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

const Layout = ({ children, fixed, isWorkLayout, showSmallsBanner }) => {
  return (
    <StyledMainContainer id="outer-container">
      {/* <PromoBanner
        text="<span>Shop Artists Sunday 20% off!</span>
        Use SUNDAY20 code on check out<br />Valid Through November 29"
      /> */}
      {showSmallsBanner && (
        <ShopSmallBanner backgroundColor="pink" textColor="black" />
      )}
      <Header2 siteTitle="Jennifer Moné Hill Artist" />
      <div id="page-wrap">{children}</div>
      <Footer fixed={fixed} isWorkLayout={isWorkLayout} />
    </StyledMainContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  isWorkLayout: PropTypes.bool,
  showSmallsBanner: PropTypes.bool,
};

export default Layout;
