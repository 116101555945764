/* eslint-env browser */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import _map from 'lodash.map';
import { Location } from '@reach/router';
import { sendSocialAnalytics } from '../../util';
import { menuLinks } from '../../constants';

const Menu = loadable(() => import('react-burger-menu/lib/menus/slide'));

const MobileHeaderBox = styled.header`
  width: 100%;
  width: 100%;
  margin: 0 auto 15px auto;
`;

export const MobileName = styled.h1`
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-size: 24px !important;
  font-weight: 400;
  text-align: left;
  margin: 15px 0 0 15px;
  a {
    font-family: 'Raleway', sans-serif;
    text-decoration: none;
  }
  @media only screen and (max-width: 376px) {
    font-size: 21px !important;
  }
`;

const MobileEmailLogo = styled.img`
  margin: 0 0 0 15px;
  height: 24px;
  transform: translateY(5px);
  filter: invert(100%);
`;

const MobileCartLogo = styled.img`
  margin: 0 0 0 15px;
  height: 24px;
  transform: translateY(5px);
  filter: invert(100%);
`;

const MobileHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  const handleStateChange = state => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = destination => {
    setMenuOpen(false);
    if (destination) {
      sendSocialAnalytics(destination);
    }
  };

  const renderLinks = () => {
    return _map(menuLinks, (link, title) => {
      return (
        <Link
          to={link}
          onClick={() => closeMenu()}
          key={title}
          alt={title === 'More Info' ? 'More Information' : title}
        >
          {title}
        </Link>
      );
    });
  };

  return (
    <MobileHeaderBox>
      <MobileName>
        <Link to="/">Jennifer Moné Hill Abstract Art</Link>
      </MobileName>
      <Menu
        disableAutoFocus
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        right
        isOpen={menuOpen}
        onStateChange={state => handleStateChange(state)}
      >
        <Location>
          {({ location }) =>
            location.pathname !== '/' && (
              <Link to="/" className="bm-item" onClick={() => closeMenu()}>
                Home
              </Link>
            )
          }
        </Location>
        {renderLinks()}
        <a
          href="mailto:jmonehill@gmail.com?subject=Contact From jmonehill.com"
          onClick={() => closeMenu('Email')}
          rel="noopener noreferrer"
        >
          <span className="white">Email</span>
          <MobileEmailLogo src="/envelope-o.svg" height="24px" width="24px" />
        </a>
        <div className="snipcart-checkout" onClick={() => closeMenu()}>
          <span className="white">Cart</span>
          <MobileCartLogo
            src="/shopping-cart.svg"
            height="24px"
            width="22.28px"
          />
        </div>
      </Menu>
    </MobileHeaderBox>
  );
};

MobileHeader.propTypes = {
  location: PropTypes.object,
};

export default MobileHeader;
