/* eslint-env browser */
import _clamp from 'lodash.clamp';
import _sortedUniq from 'lodash.sorteduniq';
import _filter from 'lodash.filter';
import _keys from 'lodash.keys';
import _get from 'lodash.get';
import _reduce from 'lodash.reduce';
import _last from 'lodash.last';
import _isEmpty from 'lodash.isempty';
import { imageBase } from '../constants';

export const getBackAndNext = (
  works,
  index,
  length,
  smallWorksURLs,
  smallWorksLength,
  isSmallWork = false
) => {
  if (isSmallWork) {
    works = smallWorksURLs;
    length = smallWorksLength
  }
  const res = {};
  const desiredNext = index + 1;
  const desiredPrevious = index - 1;

  const lastWorkIndex = length - 1;
  // get next url
  if (desiredNext <= lastWorkIndex) {
    res.next = works[desiredNext].url;
  } else res.next = works[0].url;
  // get previous url
  if (desiredPrevious >= 0) {
    res.back = works[desiredPrevious].url;
  } else res.back = works[length - 1].url;

  return res;
};

export const getRatio = (smallImageHeight, smallImageWidth, state) => {
  // check for very tall aspect ratio phones
  const isVeryWideScreen = state.width / state.height > 1.75;
  const isHorizontalMobileDevice = state.height <= 375;
  const isVerticalMobileDevice = state.width <= 375;
  const isTallScreen = state.height / state.width > 1.75;
  // mobile device in portrait mode
  if (isTallScreen || isVerticalMobileDevice) {
    return (0.82 * state.width) / smallImageWidth;
  }
  // horizontal image case
  if (smallImageWidth > smallImageHeight) {
    let horizontalScalingFactor =
      isVeryWideScreen && isHorizontalMobileDevice ? 1 : 1;
    const ratio = smallImageWidth / smallImageHeight;

    // below fixes squarish images, ones that are a bit wider than tall
    if (ratio < 1.1) {
      horizontalScalingFactor = 0.9;
    }

    const windowWidth = state.width * horizontalScalingFactor;
    return windowWidth / smallImageWidth;
  }
  //vertical image case
  const verticalScalingFactor = 1;
  const windowHeight = state.height * verticalScalingFactor;
  return windowHeight / smallImageHeight;
};

export const getRatioNew = (smallImageHeight, smallImageWidth, state) => {
  let ratioBetweenBoxAndImage = null;

  ratioBetweenBoxAndImage = state.width / smallImageWidth;
  // now check to see if image is too tall
  let calculatedImageHeight = ratioBetweenBoxAndImage * smallImageHeight;

  if (calculatedImageHeight > state.height) {
    const sizingDownRatio = state.height / calculatedImageHeight;
    return ratioBetweenBoxAndImage * sizingDownRatio;
  }

  return ratioBetweenBoxAndImage;
};

export const getSmallImageHeight = (
  ratio,
  smallImageHeight,
  smallImageWidth,
  state
) => {
  //below case if for horizontal image when screen is between 750px and 1150px, it then resets the ratio
  if (
    state.width < 1150 &&
    state.width > 750 &&
    smallImageWidth > smallImageHeight
  ) {
    // const scaleFactor = state.width < 950 ? 0.55 : 0.62;
    const scaleFactor = 1;
    const maxWidth = Math.trunc(
      _clamp(smallImageWidth * ratio, 0, state.width * scaleFactor)
    );
    ratio = maxWidth / smallImageWidth;
  }
  return Math.trunc(smallImageHeight * ratio);
};

export const getSmallImageWidth = (heightRatio, smallImageWidth, state) => {
  if (state.width < 1150 && state.width > 750) {
    // const scaleFactor = state.width < 950 ? 0.55 : 0.62;
    const scaleFactor = 1;
    return Math.trunc(
      _clamp(smallImageWidth * heightRatio, 0, state.width * scaleFactor)
    );
  }
  return Math.trunc(smallImageWidth * heightRatio);
};

export const sendSocialAnalytics = (destination) => {
  if (typeof window !== 'undefined') {
    if (!window.gtag) return;
    window.gtag('event', 'click', {
      event_category:
        destination === 'Email' ? 'Email Navigate' : 'Social Navigate',
      event_label: destination,
    });
  }
};

export const buildSrcSet = (image) => {
  const imageKeys = _filter(_sortedUniq(_keys(image)), (key) => {
    if (key.includes('image')) {
      return key;
    }
  });

  return _reduce(
    imageKeys,
    (accumulator, currentItem, index) => {
      const length = imageKeys.length;
      const imageName = _get(image, [currentItem]);
      if (_isEmpty(imageName)) return accumulator;
      let imageResolution = _last(imageName.split('-'));
      imageResolution = imageResolution.split('.')[0];
      return `${accumulator}${imageBase + imageName} ${imageResolution}w${
        index !== length - 1 ? ',' : ''
      }`;
    },
    ''
  );
};

export const sendAnalytics = (eventPayload, type) => {
  if (!window.gtag) return; // eslint-disable-line
  typeof window !== 'undefined' &&
    window.gtag('event', `${type}`, eventPayload);
};

export const isTestEnvironment = () => {
  return process.env.NODE_ENV === 'test'; // eslint-disable-line
};
