import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const BannerBox = styled.div`
  width: 100%;
  min-height: 45px;
  background: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 50px;
  line-height: 24px;
  p,
  span,
  a {
    color: ${(props) => props.textColor};
    font-size: 20px;
    text-align: center;
    display: block;
    height: min-content;
    margin: 0;
  }
  span {
    font-weight: bold;
  }
  @media only screen and (max-width: 618px) {
    line-height: 20px;
    padding: 5px;
    p,
    span,
    a {
      color: ${(props) => props.textColor};
      font-size: 16px;
      text-align: center;
      display: block;
      height: min-content;
      margin: 0;
    }
  }
`;

export const ShopSmallBanner = ({
  backgroundColor = 'black',
  textColor = 'white',
}) => {
  return (
    <Link to="/small-works">
      <BannerBox backgroundColor={backgroundColor} textColor={textColor}>
        <p>NEW! Shop Small Art</p>
      </BannerBox>
    </Link>
  );
};

ShopSmallBanner.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};
