import styled from 'styled-components';
import {
  textColor,
  mediumBreakpointMinus,
  mediumBreakpoint,
  largeBreakpointMinus,
  largeBreakpoint,
} from '../../../constants';

export const FooterBox = styled.div`
  max-width: ${largeBreakpoint};
  display: flex;
  margin: ${props =>
    props.isWorkLayout ? '20px auto 40px auto' : '50px auto 40px auto'};
  padding: 0 15px;
  box-sizing: border-box;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    flex-direction: column;
    flex-wrap: unset;
    width: 100vw;
    align-items: center;
  }
  @media only screen and (min-width: ${mediumBreakpoint}) and (max-width: ${largeBreakpointMinus}) {
    width: ${mediumBreakpoint};
  }
  @media only screen and (min-width: ${largeBreakpoint}) {
    width: ${largeBreakpoint};
  }
`;

export const FixedFooterBox = styled(FooterBox)`
  position: fixed;
  bottom: 0;
  background: white;
  border-top: 1px solid lightgray;
  width: 100vw !important;
  max-width: none;
  padding: 15px 40px;
  margin: 0 auto;
`;

export const FooterTopBox = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 0 auto;
  box-sizing: border-box;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    flex-direction: column;
    flex-wrap: unset;
    width: 100%;
    align-items: center;
    p {
      width: 100%;
      text-align: center;
    }
  }
`;

export const Ul = styled.ul`
  width: 56%;
  max-width: 90vw;
  height: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 414px) {
    width: 100%;
    margin-top: 8px;
    max-width: unset;
    flex-wrap: wrap;
  }
  li {
    margin: 0px;
    font-size: 14px;
    height: 21px;
    color: ${textColor};
    i,
    img {
      margin-left: 20px;
    }
  }
  a,
  li {
    display: inline-block;
  }
`;

export const Name = styled.p`
  font-size: 16px;
  color: ${textColor};
  margin: 0;
  text-align: left;
  width: 40%;
`;
export const InstagramLogo = styled.img`
  height: 19px;
  transform: translateY(4px);
  opacity: 0.55;
  margin-left: 24px !important;
`;

export const FacebookLogo = styled.img`
  height: 19px;
  transform: translateY(4px);
  opacity: 0.45;
`;

export const EmailIcon = styled.img`
  height: 19px;
  width: 25px;
  transform: translateY(4px);
  opacity: 0.7;
`;

export const FooterLink = styled.a``;

export const FooterTextLi = styled.li`
  a {
    min-width: 48px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    padding: ${props => (props.addPadding ? '0 5px' : '0')};
    @media only screen and (max-width: 414px) {
      height: ${props => (props.logo ? '36px' : '27px')};
      line-height: ${props => (props.logo ? '36px' : '27px')};
      margin-top: ${props => (props.logo ? '10px' : '0')};
      margin-left: ${props => (props.marginLeft ? '10.5vw' : '0')};
    }
  }
`;
