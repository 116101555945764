import styled from 'styled-components';

import {
  screenWidth,
  transitionTime,
  textColor,
  smallBreakpoint,
} from '../../../constants';

export const HeaderBox = styled.header`
  width: 100%;
  max-width: ${screenWidth};
  margin: 0 auto 0 auto;
`;

export const Name = styled.h1`
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-size: 34px;
  font-weight: 400;
  text-align: center;
  margin: 30px 0 0 0;
  a {
    font-family: 'Raleway', sans-serif;
    text-decoration: none;
    transition: all ${transitionTime} ease;
    &:hover {
      opacity: 0.8;
    }
  }
  @media only screen and (max-width: ${smallBreakpoint}) {
    font-size: 31px;
  }
`;

export const Ul = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: white;
  list-style: none;
  padding: 0 15px;
  max-width: 1024px;
  margin: 14px auto 20px auto;
  li {
    margin: 0;
    white-space: nowrap;
    a {
      text-transform: uppercase;
      font-size: 13px;
      text-decoration: none;
      line-height: 22px;
      border-bottom: 2px solid white;
      height: 22px;
      display: block;
      transition: all ${transitionTime} ease;
      &:visited {
        color: ${textColor};
      }
      &:hover {
        border-color: ${textColor};
        opacity: 0.8;
      }
    }
  }
  .social-logo {
    height: 24px;
    margin: 0 10px;
    a {
      &:hover {
        border-color: white;
      }
    }
  }
  @media only screen and (max-width: ${smallBreakpoint}) {
    justify-content: space-around;
    li {
      margin: 0;
    }
  }
  .snipcart-summary {
    position: absolute;
    left: 21px;
    top: 8px;
    background: rgba(40, 40, 40, 0.5);
    width: 18px;
    height: 18px;
    border-radius: 27px;
    display: ${props => (props.showCartQty ? 'inherit' : 'none')};

  }
  .snipcart-total-items {
    color: white;
    font-size: 15px;
    line-height: 18px;
    width: 18px;
    text-align: center;
    display: block;
  }
`;

export const EmailIcon = styled.img`
  height: 20px;
  width: 27px;
  transform: translateY(-1.5px);
  opacity: 0.7;
`;

export const CartIcon = styled.img`
  height: 25px;
  width: 27px;
  transform: translateY(-4px);
  opacity: 0.4;
  font-size: 19px;
`;
