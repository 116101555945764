import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { imageBase } from '../constants';

const SEO = ({
  description,
  lang,
  meta,
  keywords,
  title = 'Jennifer Mone Hill Artist',
}) => {
  const metaDescription =
    description || 'Portfolio Site for Jennifer Mone Hill Artist';

  return (
    <Helmet
      htmlAttributes={{
        lang,
        xmlns: "http://www.w3.org/1999/xhtml",
        'xmlns:fb': "http://ogp.me/ns/fb#"
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=6.0',
        },

        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `${imageBase}Spectrum-by-Jennifer-Mone-Hill-300-200.jpg`,
        },
        {
          property: 'og:url',
          content: 'https://jmonehill.com/',
        },

        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: 'Jennifer Mone Hill',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;