import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { sendSocialAnalytics } from '../util';
import { imageBase } from '../constants';
import * as S from './sub-components/styled-components/footerSyledComponents';

class Footer extends Component {
  renderInnerFooter(year) {
    return (
      <S.FooterTopBox>
        <S.Name>
          Copyright &copy; {`2017-${year}`} Jennifer Hill
          <br />
          All rights reserved.
        </S.Name>

        <S.Ul>
          <S.FooterTextLi addPadding>
            <Link to={'/'}>Home</Link>
          </S.FooterTextLi>
          <S.FooterTextLi addPadding>
            <Link to={'/about'}>About</Link>
          </S.FooterTextLi>
          <S.FooterTextLi addPadding>
            <Link to={'/contact'}>Contact</Link>
          </S.FooterTextLi>
          <S.FooterTextLi addPadding>
            <Link to={'/blog'}>Blog</Link>
          </S.FooterTextLi>
          <S.FooterTextLi logo marginLeft>
            <S.FooterLink
              href="mailto:jmonehill@gmail.com?subject=Contact From jmonehill.com"
              rel="noopener noreferrer"
              onClick={() => sendSocialAnalytics('Email')}
              aria-label="Contact Jennifer Mone Hill via Email"
            >
              <S.EmailIcon
                src={`${imageBase}email-icon.png`}
                alt="Email link icon"
              />
            </S.FooterLink>

            <S.FooterLink
              href="https://www.instagram.com/jmonehill/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => sendSocialAnalytics('Instagram')}
              aria-label="Go to Jennifer Mone Hill Instagram Page"
            >
              <S.InstagramLogo
                src="/instagram.svg"
                alt="Instagram Logo"
                width="16.28px"
                height="19px"
              />
            </S.FooterLink>
            <S.FooterLink
              href="https://www.facebook.com/jmonehill/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => sendSocialAnalytics('Facebook')}
              aria-label="Go to Jennifer Mone Hill Facebook Page"
            >
              <S.FacebookLogo
                src="/facebook.svg"
                alt="Facebook Logo"
                width="10.84px"
                height="19px"
              />
            </S.FooterLink>
          </S.FooterTextLi>
        </S.Ul>
      </S.FooterTopBox>
    );
  }
  render() {
    const d = new Date();
    const year = d.getFullYear();
    const { fixed, isWorkLayout } = this.props;
    if (!fixed) {
      return (
        <S.FooterBox fixed={fixed} isWorkLayout={isWorkLayout}>
          {this.renderInnerFooter(year)}
        </S.FooterBox>
      );
    }
    return (
      <S.FixedFooterBox fixed={fixed}>
        {this.renderInnerFooter(year)}
      </S.FixedFooterBox>
    );
  }
}

Footer.propTypes = {
  fixed: PropTypes.bool,
  isWorkLayout: PropTypes.bool,
};

export default Footer;
