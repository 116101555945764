/* eslint-env browser */
import React, { useContext } from 'react';
import { Link } from 'gatsby';
import _map from 'lodash.map';
import _get from 'lodash.get';
import { SnipcartContext } from 'gatsby-plugin-snipcart-advanced/context';

import { sendSocialAnalytics } from '../../util';
import { imageBase, menuLinks } from '../../constants';
import * as S from './styled-components/desktopHeaderStyles';

const DesktopHeader = () => {
  const { state } = useContext(SnipcartContext);

  const cartQuantity = _get(state, 'cartQuantity', 0);

  const renderLinks = () => {
    return _map(menuLinks, (link, title) => {
      return (
        <li key={title}>
          <Link
            to={link}
            alt={title === 'More Info' ? 'More Information' : title}
          >
            {title}
          </Link>
        </li>
      );
    });
  };

  return (
    <S.HeaderBox id="home">
      <S.Name>
        <Link to="/">Jennifer Moné Hill Abstract Art</Link>
      </S.Name>
      <nav>
        <S.Ul showCartQty={cartQuantity > 0}>
          {renderLinks()}
          <li className="social-logo">
            <a
              href="mailto:jmonehill@gmail.com?subject=Contact From jmonehill.com"
              rel="noopener noreferrer"
              onClick={() => sendSocialAnalytics('Email')}
            >
              <S.EmailIcon
                src={`${imageBase}email-icon.png`}
                alt="Email Link Icon"
              />
            </a>
          </li>
          <li className="social-logo">
            <a rel="noopener noreferrer" className="snipcart-checkout">
              <S.CartIcon src='/shopping-cart.svg' />
              <div className="snipcart-summary">
                {cartQuantity && (
                  <span className="snipcart-total-items">{cartQuantity}</span>
                )}
              </div>
            </a>
          </li>
        </S.Ul>
      </nav>
    </S.HeaderBox>
  );
};

export default DesktopHeader;
