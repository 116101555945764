import React, { Component } from 'react';
import DesktopHeader from './sub-components/desktopHeader';
import MobileHeader from './sub-components/mobileHeader';
import styled from 'styled-components';
import './sub-components/mobileHeader.css';

const BrowserView = styled.div`
  display: none;
  @media only screen and (min-width: 744px) {
    display: block;
    max-width: 98vw;
  }
`;

const MobileView = styled.div`
  display: block;
  @media only screen and (min-width: 744px) {
    display: none;
  }
`;

class Header extends Component {
  render() {
    return [
        <BrowserView key='browser-header'>
          <DesktopHeader />
        </BrowserView>,
        <MobileView key='mobile-header'>
          <MobileHeader />
        </MobileView>
    ];
  }
}

export default Header;
